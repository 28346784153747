.Home__content__wrapper {
  display: flex;
  padding: 98px 16px 0px;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;
}
.Home__portrait {
  background-image: url(../assets/eva-portrait.jpg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100%;
  width: 100%;
}

.Home__bio__parapgraph {
  margin-bottom: 12px !important;
}

@media (min-width: 1230px) {
  
  .Home__content__wrapper {
    flex-direction: row;
    height: auto;
    padding: 114px 32px 68px;
  }

  .Home__portrait {
    padding-bottom: 136%;
  }
}
