*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  font-size: 100%;
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Editorial New", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #225349;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

img[height],
img[width] {
  max-width: none;
}

svg {
  height: auto;
}

a,
p {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  line-height: 130%;
}

a {
  border-bottom: 1px solid #225349;;
  padding-bottom: 1px;
}

li:not(:last-child),
p:not(:last-child) {
  margin-bottom: 3px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

@media (min-width: 1230px) {
  .h-170 {
    height: 220px;
  }

  .h-120 {
    height: 120px;
  }
}

@media (min-width: 1325px) {
  .h-170 {
    height: 190px;
  }
}

@media (min-width: 1413px) {
  .h-170 {
    height: 184px;
  }
}