.Layout__header {
  position: absolute;
  left: 0;
  top: 0;
}

.Layout__footer {
  flex-direction: column;
  background: #F2F0B4;
  z-index: 1;
}

.Layout__header,
.Layout__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Layout__header {
  padding: 24px 16px;
}

.Layout__footer {
  padding: 20px 16px;
}

.selected {
  font-weight: bold;
}

.Layout__footer__links,
.Layout__navigation__links {
  display: flex;
}

.Layout__footer__links {
  flex-direction: column;
}

.Layout__footer__link:not(:last-child),
.Layout__navigation__link:not(:last-child) {
  margin-right: 12px;
}

.Layout__footer__sustainable-blurb {
  display: flex;
  white-space: nowrap;
}


.Layout__footer__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (min-width: 1230px) {
  .Layout__header {
    padding: 32px;
    height: 82px;
  }

  .Layout__footer {
    padding: 24px 32px;
    height: 68px;
    position: fixed;
    bottom: 0;
    left: 0;
    flex-direction: row;
  }

  .Layout__footer__links {
    flex-direction: row;
  }

  .Layout__footer__content {
    flex-direction: row;
  }
}
