.App {
  text-align: left;
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100vw;
  overflow: auto;
  background: #fdf8ba;
  color: #225349;
  flex-direction: column;
}

body {
  background: #fdf8ba;
}

main {
  width: 100%;
}

.content__item {
  margin-bottom: 48px;
}

.content__item--compact {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .content__item {
    max-width: 70%;
  }
}

@media (min-width: 1230px) {
  .App {
    flex-direction: row;
  }

  .content__item {
    margin-bottom: 32px;
    max-width: 405px;;
  }

  .content__group:not(:last-child) {
    margin-right: 80px;
  }
}
